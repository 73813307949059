<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow v-if="user.roles != 1">
          <CCol class="text-center">
            <h1 v-if="bell.length == 0">
              CAMPAÑA NO INICIADA, COMUNIQUESE CON EL SUPERADMINISTRADOR PARA
              APERTURAR UNA CAMAPAÑA.
            </h1>
          </CCol>
        </CRow>
        <CRow v-else>
          <CRow v-if="bell.length == 0">
            <CCol class="text-center" xl="12" lg="12" md="12" sm="12" xs="12">
              <h2>DEBE APERTURAR UNA CAMPAÑA</h2>
            </CCol>

            <CCol xl="4" lg="4" md="4" sm="12" xs="12">
              <CInput label="Nombre de campaña" placeholder="Nombre" v-model="item.name" />
            </CCol>
            <CCol xl="4" lg="4" md="4" sm="12" xs="12">
              <CInput type="date" label="Desde" v-model="item.date_start" />
            </CCol>
            <CCol xl="4" lg="4" md="4" sm="12" xs="12">
              <CInput type="date" label="Hasta" v-model="item.date_end" />
            </CCol>
            <CCol class="text-center" xl="12" lg="12" md="12" sm="12" xs="12">
              <CButton
                color="info"
                v-c-tooltip.hover="{
                  content: 'Aperturar',
                }"
                :disabled="cp_button"
                @click="mtd_aperturar"
              >
                <i class="fas fa-save"></i> Aperturar
              </CButton>
            </CCol>
          </CRow>
          <CRow lg="12" md="12" sm="12" xs="12" v-else>
            <CCol class="text-center" xl="12" lg="12" md="12" sm="12" xs="12">
              <h2>CAMPAÑA <strong>{{ bell['name'] }}</strong> APERTURADA</h2> 
            </CCol>
          </CRow>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../main";
export default {
  name: "home",
  data() {
    return {
      bell: [],
      user: [],
      item:{
        name:"",
        date_start:null,
        date_end:null
      }
    };
  },
  computed: {
    cp_button:function(){
      if( this.item.name.length > 0 && this.item.date_start != null && this.item.date_end) return false;
      return true;
    }
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/home",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.user = this.$store.getters.get__user;
          this.bell = response;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_aperturar: function(){
      this.post({
        url: this.$store.getters.get__url + "/home/aperturar",
        token: this.$store.getters.get__token,
        params:this.item
      })
        .then((response) => {
          console.log(response);
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "CAMPAÑA APERTURADA!!",
            });
            this.bell=response.data;
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    }
  },
};
</script>
